import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../../global.service';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: [],
  providers: [GlobalService]
})
export class LogoutComponent implements OnInit {
  constructor(private _GlobalService: GlobalService) {
  }
  ngOnInit(): void {
   
   
    this._GlobalService.RemoveLoggedUser();
    this._GlobalService.GoToPage('auth/login');
  }
}
