import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { LogoutComponent } from './views/pages/auth/logout/logout.component';
LogoutComponent
const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'logout',
        loadChildren: () => import('./views/pages/auth/logout/logout.component').then(m => m.LogoutComponent)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'Signings',
        loadChildren: () => import('./views/pages/signing-orders/signing-orders.module').then(m => m.SigningOrdersModule)
      },
      {
        path: 'Clients',
        loadChildren: () => import('./views/pages/clients-module/clients-module.module').then(m => m.ClientsModuleModule)
      },
      {
        path: 'Notary',
        loadChildren: () => import('./views/pages/notary-module/notary-module.module').then(m => m.NotaryModuleModule)
      },
      {
        path: 'Chat',
        loadChildren: () => import('./views/pages/chat-module/chat-module.module').then(m => m.ChatModuleModule)
      },
      {
        path: 'Profile',
        loadChildren: () => import('./views/pages/user-profile-module/user-profile-module.module').then(m => m.UserProfileModuleModule)
      },
      {
        path: 'Notifications',
        loadChildren: () => import('./views/pages/inbox-module/inbox-module.module').then(m => m.InboxModuleModule)
      },
      {
        path: 'Imports',
        loadChildren: () => import('./views/pages/imports-module/imports-module.module').then(m => m.ImportsModuleModule)
      },
      {
        path: 'Exports',
        loadChildren: () => import('./views/pages/exports-module/exports-module.module').then(m => m.ExportsModuleModule)
      },
      {
        path: 'Admin',
        loadChildren: () => import('./views/pages/account-settings-mgnt/account-settings-mgnt.module').
          then(m => m.AccountSettingsMgntModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
