import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { GlobalService } from '../../../global.service';
import { MasterServicesService } from '../../../services/master-services.service';
import { interval } from 'rxjs';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [GlobalService, MasterServicesService]
})
export class NavbarComponent implements OnInit {
  loggedInUser: any = {};
  showMasters = false;
  unReadInboxCount = 0;
  unReadMsgCount=0;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _GlobalService: GlobalService,
    private _API: MasterServicesService
  ) { }
  ngOnInit(): void {
    this.loggedInUser = this._GlobalService.GetLoggedUser();
    const loggedUserType = this._GlobalService.GetLoggedUserType();
    if (loggedUserType === 'A'||loggedUserType === 'AC') { this.showMasters = true; }
    this.GetActivityLogCount();
    this.GetUnreadMsg();
    // interval(5000).subscribe(x => { this.GetActivityLogCount(); });
  }
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  GetActivityLogCount() {
    this._API.GetActivityLog({ pageNumber: 1, pageSize: 1 }).subscribe(data => {
      const unReadCount = 'unReadCount';
      if (data[unReadCount]) { this.unReadInboxCount = data[unReadCount]; }
    }, error => { });
  }
  GetUnreadMsg() {

    this._API.GetUnreadMsg({ pageNumber: 1, pageSize: 1 }).subscribe((data :any)=> {
       this.unReadMsgCount = data.Response.result;
       
    }, error => { });
  }

  
}
