import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalService } from './global.service'
import { Location } from '@angular/common';
@Component({
  selector: 'app-signing247-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Signing247';
  location: Location;
  constructor(private router: Router, private _GlobalService: GlobalService) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (this.tokenExpired()) {
          if (ev.url !== '/auth/login') {
            this._GlobalService.GoToPage('auth/Logout');
          }
        }
      }
    });
  }
  ngOnInit(): void {
  //   if (location.protocol === 'http:') {
  //     window.location.href = location.href.replace('http', 'http');
  //  }
  }
  private tokenExpired(): boolean {
    return false;
    // const UData = this._GlobalService.GetLoggedUser();
    // if (UData != null) {
    //   const expiry = UData.TokenExiperedDate;
    //   if ((((Math.floor(new Date(expiry).getTime() / 1000)) - Math.floor((new Date).getTime() / 1000))) > 0) { return true; }
    // }
    // return false;
  }

}
