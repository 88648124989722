import { MenuItem } from './menu.model';
export const ADMIN_MENU: MenuItem[] = [
  { label: 'Dashboard', icon: 'home', link: '/dashboard' },
  { label: 'Signings', isTitle: true },
  { label: 'New Signing', icon: 'plus', link: '/Signings/NewSigning' },
  // { label: 'Search Signing', icon: 'search', link: '/Signings/Details', },
  { label: 'Search Signing', icon: 'search', link: '/Signings/Dashboard/SearchSignings', },
  { label: 'All Signings', icon: 'file-text', link: '/Signings/Dashboard/All' },
  { label: 'Titleloop Signings', icon: 'file-text', link: '/Signings/TitleNewSigning',badge:'badge badge-success ml-2' },
  { label: 'Open Signings', isTitle: true },
  { label: 'All Open', icon: 'file-text', link: '/Signings/Dashboard/AllOpenedSignings' },
  { label: 'New', icon: 'file-text', link: '/Signings/Dashboard/AllNewSignings' },
  { label: 'Notified', icon: 'file-text', link: '/Signings/Dashboard/Notified' },
  { label: 'Scheduled', icon: 'file-text', link: '/Signings/Dashboard/Scheduled' },
  { label: 'Confirmed', icon: 'file-text', link: '/Signings/Dashboard/Confirmed' },
  { label: 'Pending Upload', icon: 'file-text', link: '/Signings/Dashboard/PendingUpload' },
  { label: 'Pending Review', icon: 'file-text', link: '/Signings/Dashboard/PendingReview' },
  { label: 'Pending Return', icon: 'file-text', link: '/Signings/Dashboard/PendingReturn' },

  { label: 'Closed Signings', isTitle: true },
  { label: 'All Closed', icon: 'file-text', link: '/Signings/Dashboard/AllClosedSignings' },
  { label: 'Signed', icon: 'file-text', link: '/Signings/Dashboard/Signed' },
  { label: 'Did Not Sign', icon: 'file-text', link: '/Signings/Dashboard/DidnotSigned' },
  { label: 'Cancelled', icon: 'file-text', link: '/Signings/Dashboard/Cancelled' },
  { label: 'On Hold', icon: 'file-text', link: '/Signings/Dashboard/OnHold' },
  
  { label: 'Clients', isTitle: true },
  { label: 'New Client', icon: 'plus', link: '/Clients/NewClient' },
  { label: 'All Clients', icon: 'award', link: '/Clients/Dashboard' },
  { label: 'Search Client', icon: 'search', link: '/Clients/Details', },
  { label: 'Notary', isTitle: true },
  { label: 'New Notary', icon: 'user-plus', link: '/Notary/NewNotary' },
  { label: 'All Notary', icon: 'users', link: '/Notary/Dashboard' },
  // {label: 'Search Notary',icon: 'search',link: '/Notary/Details',},

  { label: 'Imports', isTitle: true },
  { label: 'Client Payments', icon: 'dollar-sign', link: '/Imports/ClientPayments' },
  { label: 'Notary Payments', icon: 'dollar-sign', link: '/Imports/NotaryPayments' },


  { label: 'Exports', isTitle: true },
  { label: 'Signings Export', icon: 'file-text', link: '/Exports/Signings' },
  { label: 'Clients Export', icon: 'award', link: '/Exports/Clients', },
  { label: 'Notary Export', icon: 'users', link: '/Exports/Notary' },

];

export const ACCOUNT_MENU: MenuItem[] = [
  { label: 'Dashboard', icon: 'home', link: '/dashboard' },
  { label: 'Signings', isTitle: true },
  { label: 'New Signing', icon: 'plus', link: '/Signings/NewSigning' },
  //{ label: 'Search Signing', icon: 'search', link: '/Signings/Details', },
  { label: 'Search Signing', icon: 'search', link: '/Signings/Dashboard/SearchSignings', },

  { label: 'All Signings', icon: 'file-text', link: '/Signings/Dashboard/All' },
  { label: 'Open Signings', isTitle: true },
  { label: 'All Open', icon: 'file-text', link: '/Signings/Dashboard/AllOpenedSignings' },
  { label: 'New', icon: 'file-text', link: '/Signings/Dashboard/AllNewSignings' },
  { label: 'Notified', icon: 'file-text', link: '/Signings/Dashboard/Notified' },
  { label: 'Scheduled', icon: 'file-text', link: '/Signings/Dashboard/Scheduled' },
  { label: 'Confirmed', icon: 'file-text', link: '/Signings/Dashboard/Confirmed' },
  { label: 'Pending Upload', icon: 'file-text', link: '/Signings/Dashboard/PendingUpload' },
  { label: 'Pending Review', icon: 'file-text', link: '/Signings/Dashboard/PendingReview' },
  { label: 'Pending Return', icon: 'file-text', link: '/Signings/Dashboard/PendingReturn' },

  { label: 'Closed Signings', isTitle: true },
  { label: 'All Closed', icon: 'file-text', link: '/Signings/Dashboard/AllClosedSignings' },
  { label: 'Signed', icon: 'file-text', link: '/Signings/Dashboard/Signed' },
  { label: 'Did Not Sign', icon: 'file-text', link: '/Signings/Dashboard/DidnotSigned' },
  { label: 'Cancelled', icon: 'file-text', link: '/Signings/Dashboard/Cancelled' },
  { label: 'On Hold', icon: 'file-text', link: '/Signings/Dashboard/OnHold' },
  

  // { label: 'Clients', isTitle: true },
  // { label: 'New Client', icon: 'plus', link: '/Clients/NewClient' },
  // { label: 'All Clients', icon: 'award', link: '/Clients/Dashboard' },
  // { label: 'Search Client', icon: 'search', link: '/Clients/Details', },
  
  { label: 'Notary', isTitle: true },
  { label: 'New Notary', icon: 'user-plus', link: '/Notary/NewNotary' },
  { label: 'All Notary', icon: 'users', link: '/Notary/Dashboard' },
  // {label: 'Search Notary',icon: 'search',link: '/Notary/Details',},

  // { label: 'Imports', isTitle: true },
  // { label: 'Client Payments', icon: 'dollar-sign', link: '/Imports/ClientPayments' },

  // { label: 'Exports', isTitle: true },
  // { label: 'Signings Export', icon: 'file-text', link: '/Exports/Signings' },
  // { label: 'Clients Export', icon: 'award', link: '/Exports/Clients', },
  // { label: 'Notary Export', icon: 'users', link: '/Exports/Notary' },

];

export const CLIENT_MENU: MenuItem[] = [
  { label: 'Dashboard', icon: 'home', link: '/dashboard' },
  { label: 'Signings', isTitle: true },
  { label: 'New Signing', icon: 'plus', link: '/Signings/NewSigning' },
 // { label: 'Search Signing', icon: 'search', link: '/Signings/Details', },
  { label: 'Search Signing', icon: 'search', link: '/Signings/Dashboard/SearchSignings', },

  // { label: 'All Signings', icon: 'file-text', link: '/Signings/Dashboard/All' },
  { label: 'Open Signings', isTitle: true },
  { label: 'All Open', icon: 'file-text', link: '/Signings/Dashboard/AllOpenedSignings' },
  { label: 'New', icon: 'file-text', link: '/Signings/Dashboard/AllNewSignings' },
  { label: 'Notified', icon: 'file-text', link: '/Signings/Dashboard/Notified' },
  { label: 'Scheduled', icon: 'file-text', link: '/Signings/Dashboard/Scheduled' },
  { label: 'Confirmed', icon: 'file-text', link: '/Signings/Dashboard/Confirmed' },
  { label: 'Pending Upload', icon: 'file-text', link: '/Signings/Dashboard/PendingUpload' },
  { label: 'Pending Review', icon: 'file-text', link: '/Signings/Dashboard/PendingReview' },
  { label: 'Pending Return', icon: 'file-text', link: '/Signings/Dashboard/PendingReturn' },

  { label: 'Closed Signings', isTitle: true },
  { label: 'All Closed', icon: 'file-text', link: '/Signings/Dashboard/AllClosedSignings' },
  { label: 'Signed', icon: 'file-text', link: '/Signings/Dashboard/Signed' },
  { label: 'Did Not Sign', icon: 'file-text', link: '/Signings/Dashboard/DidnotSigned' },
  { label: 'Cancelled', icon: 'file-text', link: '/Signings/Dashboard/Cancelled' },
  { label: 'On Hold', icon: 'file-text', link: '/Signings/Dashboard/OnHold' },

  { label: 'My Account', isTitle: true },
  { label: 'Account Details', icon: 'user', link: '/Clients/MyProfile' },
];

export const NOTARY_MENU: MenuItem[] = [
  { label: 'Dashboard', icon: 'home', link: '/dashboard' },
  { label: 'Signings', isTitle: true },
 // { label: 'Search Signing', icon: 'search', link: '/Signings/Details', },
  { label: 'Search Signing', icon: 'search', link: '/Signings/Dashboard/SearchSignings', },

  // { label: 'All Signings', icon: 'file-text', link: '/Signings/Dashboard/All' },
  { label: 'Open Signings', isTitle: true },
  { label: 'All Open Signings', icon: 'file-text', link: '/Signings/Dashboard/AllOpenedSignings' },
  { label: 'Closed Signings', isTitle: true },
  { label: 'All Closed Signings', icon: 'file-text', link: '/Signings/Dashboard/AllClosedSignings' },
  { label: 'Signed', icon: 'file-text', link: '/Signings/Dashboard/Signed' },
  { label: 'Did Not Sign', icon: 'file-text', link: '/Signings/Dashboard/DidnotSigned' },
  { label: 'Cancelled', icon: 'file-text', link: '/Signings/Dashboard/Cancelled' },
  { label: 'My Account', isTitle: true },
  { label: 'Account Details', icon: 'user', link: '/Notary/MyProfile' },
];
export const NO_MENU: MenuItem[] = [
  { label: 'Log Out', icon: 'log-out', link: '/Logout' },
];
