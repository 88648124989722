import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('_eS_cp_isLoggedin')) { return true; }
   // this.router.navigate(['/auth/register'], { queryParams: { returnUrl: state.url } });
    this.router.navigate(['/auth/login']);
    return false;
  }
}